import React from "react";
import { useLocation } from "react-router-dom";

export default function PdfPage() {
    const location = useLocation();
    const pdfSrc = location.state?.pdfSrc;

    return (
        <main>
            {pdfSrc ? (
                <iframe
                    src={pdfSrc}
                    width="100%"
                    height="1000px"
                    title="PDF Viewer"
                />
            ) : (
                <p>No PDF available</p>
            )}
        </main>
    );
}
