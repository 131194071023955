import React from "react";
import ReactDOM from "react-dom/client";
import "./output.css";
import App from "./App";

// Create a root to render the React app
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the React app inside the root element
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>

);

