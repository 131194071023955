import React from "react";
import logo from "../../assets/logo192_B.png";
import {ReactComponent as NightMode} from "../../assets/night_mode.svg";
import {ReactComponent as LightMode} from "../../assets/light_mode.svg";
import {useSettings} from "../../SettingContext";
import {Link, NavLink} from "react-router-dom";

const Logo = () => <img src={logo} alt="website logo" width="60px"/>;

const navLinks = [
    {to: "/", text: "Home"},
    {to: "/progetti", text: "Progetti"},
    {to: "/chi-sono", text: "Chi sono"},
    {to: "/quadri", text: "Quadri"},
    {to: "/contatti", text: "Contatti"}
];

function NavBar() {
    const {theme, toggleTheme} = useSettings();
    return (
        <div className="fixed top-0 left-0 w-full bg-white z-50 h-16 flex items-center justify-center">
            <div className="flex max-w-screen-2xl w-full mx-auto items-center justify-between whitespace-nowrap px-4">
                <Link to={"/"}>
                    <Logo/>
                </Link>
                <nav className="flex justify-center p-0 uppercase text-black">
                    {navLinks.map((link, index) => (
                        <NavLink
                            key={index}
                            to={link.to}
                            className={({isActive}) =>
                                `mx-2.5 my-0 px-2 py-1 ${isActive ? "font-bold text-link-green" : "font-medium hover:text-link-green"}`
                            }
                        >
                            {link.text}
                        </NavLink>
                    ))}
                </nav>
                <button onClick={toggleTheme}>
                    {theme === "light" ? <LightMode width="20px"/> : <NightMode width="20px"/>}
                </button>
            </div>
        </div>
    );
}

export default NavBar;
