import {createContext, useContext, useState} from "react";

const SettingContext = createContext(undefined);

export const SettingsProvider = ({children}) => {
	const [theme, setTheme] = useState("light");

	return <SettingContext.Provider
		value={{
			theme,
			toggleTheme: () => setTheme(theme === "light" ? "dark" : "light"),
		}}
	>
		{children}
	</SettingContext.Provider>;
};

export const useSettings = () => useContext(SettingContext);