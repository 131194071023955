import React, { lazy, Suspense } from "react";
import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { SettingsProvider } from "./SettingContext";
import NavBar from "./pages/elements/NavBar";
import PdfPage from "./pages/PdfPage";

// Lazy loading the pages
const Homepage = lazy(() => import("./pages/Homepage"));
const ProjectPage = lazy(() => import("./pages/ProjectPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const PaintingPage = lazy(() => import("./pages/PaintingsPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const ProjectItem = lazy(() => import("./pages/ProjectItem"));

export default function App() {
    return (
        <SettingsProvider>
            <Router>
                <ConditionalNavBar />
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/progetti" element={<ProjectPage />} />
                        <Route path="/chi-sono" element={<AboutPage />} />
                        <Route path="/quadri" element={<PaintingPage />} />
                        <Route path="/contatti" element={<ContactPage />} />
                        <Route path="/progetti/:slug" element={<ProjectItem />} />
                        <Route path="/pdf/:slug" element={<PdfPage />} />
                    </Routes>
                </Suspense>
            </Router>
        </SettingsProvider>
    );
}

// A component to conditionally render the NavBar
function ConditionalNavBar() {
    const location = useLocation();

    // Don't render NavBar for the PDF page
    if (location.pathname.startsWith("/pdf")) {
        return null;
    }

    return <NavBar />;
}
